<template>
    <div class="content_container form_library">
        <div class="content">
            <div class="banner">
                <h1>서식 자료실</h1>
            </div>

            <ListBoard whichBoard="dataRoom" />
        </div>
    </div>
</template>

<script>
import ListBoard from '@/components/ListBoard'

export default {
	name : 'DataRoom',
	components : {
		ListBoard,
	},
}
</script>

